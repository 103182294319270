import React, { forwardRef, Ref, useMemo, FocusEvent } from "react";
import DayPicker, {
  DayPickerProps,
  Modifier,
  NavbarElementProps,
} from "react-day-picker";
import "react-day-picker/lib/style.css";
import classNames from "classnames";

import moment from "moment";
import Text from "../../type/Text/Text";
import Input, { InputStatus } from "../Input/Input";
import { RoundedVariantProps } from "../Input/inputVariants";

import DropdownWrapper from "../../../utils/DropdownWrapper/DropdownWrapper";
import Icon from "../../icons/Icon/Icon";
import { ClickableDiv } from "../../../utils/ClickableDiv";
import Alerts, { AlertEnum } from "../../dialogs/Alerts/Alerts";
import useSubscription from "@src/global_functions/hooks/useSubscription";

// @todo - add next/prev year functionality after upgrading to react-day-picker v8
// https://react-day-picker.js.org/guides/upgrading

type DatePickerProps = {
  value?: string | null;
  label?: string;
  format?: string | string[];
  className?: string | null;
  disabledDates?: Modifier[];
  style?: Record<any, any>;
  handleDateChange?: (arg1: string | null) => void;
  defaultMonth?: Date;
  dataTestId?: string;
  enableFutureDates?: boolean;

  // input props

  status?: InputStatus;
  supportText?: string;
  validation?: string;
  extendContainerRefs?: React.RefObject<HTMLElement>[];
  extraDropdownRef?: React.RefObject<HTMLDivElement>;
  backgroundColor?: RoundedVariantProps["backgroundColor"];
  placeholder?: string;
};

// type DatePickerVariants = "single" | "range";
/**
 * For use in console, may encounter timezone offset issue if used in IP/Valinor
 * due to setting moment default timezone to building's timezone in `/src/site__/SiteRouter.tsx`
 * @param format provide date format if not "YYYY-MM-DD or "MMM D, YYYY"
 */
export default function DatePickerInput({
  name,
  disabled,
  value,
  label,
  status,
  supportText,
  validation,
  extendContainerRefs,
  extraDropdownRef,
  backgroundColor,
  dataTestId = "date-picker-input",
  placeholder,
  ...datePickerProps
}: Omit<DatePickerProps, "onCancel"> & {
  name: string;
  disabled?: boolean;
}) {
  const [isActive, setIsActive] = React.useState(false);
  const [closeSubscription, closeEvent] = useSubscription();
  const [_value, _setValue] = React.useState<string | null | undefined>();
  const [error, setError] = React.useState<string | null>(null);
  const containerRef = React.useRef<HTMLInputElement>(null);
  const _extraDropdownRef = React.useRef<HTMLDivElement>(null);
  const allRefs = React.useMemo(
    () =>
      [
        containerRef,
        ...(extendContainerRefs ?? []),
      ] as React.RefObject<HTMLElement>[],
    [containerRef, extendContainerRefs]
  );

  React.useEffect(() => {
    _setValue(value);
  }, [value]);

  React.useEffect(() => {
    // close dropdown on date selection.
    // todo - add additional condition for multiselect
    closeEvent();
  }, [value, closeEvent]);

  const dateInputVal = _value
    ? moment(_value, datePickerProps.format).format("YYYY-MM-DD")
    : "";
  const maxInputVal = datePickerProps.enableFutureDates
    ? "9999-12-31"
    : moment().format("YYYY-MM-DD");

  // update value in input on every change, but only call on change param if input value is a valid date
  function handleDateInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    _setValue(e.target.value);

    if (e.target.validity.valid) {
      setError(null);
      datePickerProps.handleDateChange?.(e.target.value);
    } else if (
      !datePickerProps.enableFutureDates &&
      e.target.validity.rangeOverflow
    ) {
      setError(
        "Future dates are not allowed for this field. Please select a date in the past."
      );
    }
  }

  function handleDateSelect(date: string | null) {
    datePickerProps.handleDateChange?.(date);
    setError(null);
  }

  React.useEffect(() => {
    const handleDocumentClick = (e: MouseEvent) => {
      if (
        ![...allRefs, _extraDropdownRef, extraDropdownRef].some((ref) =>
          ref?.current?.contains(e.target as Node)
        )
      ) {
        setIsActive(false);
      }
    };
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [allRefs, extraDropdownRef, _extraDropdownRef]);

  return (
    <div className="relative w-full" ref={containerRef}>
      <DropdownWrapper
        open={typeof error === "string"}
        onFocus={() => {
          setIsActive(true);
        }}
        onBlur={(e) => {
          // if local value is invalid when user clicks out, reset to last valid value
          if (
            !_value ||
            !(e as FocusEvent<HTMLInputElement>).target.validity.valid
          ) {
            datePickerProps.handleDateChange?.(null);
          }
        }}
        as={
          <Input
            dataTestId={dataTestId}
            name={name}
            label={label}
            disabled={disabled}
            placeholder={placeholder}
            value={dateInputVal ?? ""}
            // Set type to text so placeholder is shown when value is empty
            type={!_value && !isActive ? "text" : "date"}
            variant="rounded"
            onChange={handleDateInputChange}
            status={status}
            supportText={supportText}
            validation={validation}
            // min ensures date is valid and has a year with 4 digits
            min={"1000-01-01"}
            max={maxInputVal}
            backgroundColor={backgroundColor}
          />
        }
      >
        {<Alerts status={AlertEnum.Error} message={error} />}
      </DropdownWrapper>
      <DropdownWrapper
        closeSubscription={closeSubscription}
        extraRefs={allRefs}
        extraDropdownRef={extraDropdownRef ?? _extraDropdownRef}
        orientRef={containerRef}
        as={
          <Icon
            name="date-picker"
            className={classNames(
              "absolute right-4 cursor-pointer text-straps-secondary transition-colors hover:text-straps-primary",
              {
                "top-2": !label,
                "bottom-2": !!label,
              }
            )}
            data-testid={`${dataTestId}-open-picker`}
          />
        }
      >
        <DatePicker
          value={_value}
          {...datePickerProps}
          handleDateChange={handleDateSelect}
        />
      </DropdownWrapper>
    </div>
  );
}

const DayPickerNavBar = (props: NavbarElementProps) => {
  return (
    <div className="flex items-center justify-between bg-straps-primary-hover">
      <ClickableDiv
        onClick={() => props.onPreviousClick()}
        className="group flex h-10 cursor-pointer items-center px-2"
      >
        <Icon name="caret-left" className="group-hover:text-white" />
      </ClickableDiv>

      <Text variant="sb_t-14-500" color="white">
        {moment(props.month).format("MMMM YYYY")}
      </Text>

      <ClickableDiv
        className="group flex h-10 cursor-pointer items-center px-2"
        onClick={() => props.onNextClick()}
      >
        <Icon name="caret-right" className="group-hover:text-white" />
      </ClickableDiv>
    </div>
  );
};

export const DatePicker = forwardRef(function DatePicker(
  {
    value,
    format = ["YYYY-MM-DD", "MMM D, YYYY"],
    className,
    handleDateChange,
    disabledDates,
    defaultMonth,
    enableFutureDates,
    ...props
  }: DatePickerProps,
  ref: Ref<HTMLDivElement>
) {
  const disabledDays = useMemo(() => {
    const result: Modifier[] = enableFutureDates
      ? []
      : [{ after: moment().toDate() }];

    if (disabledDates) result.push(...disabledDates);
    return result;
  }, [disabledDates, enableFutureDates]);
  // moment() parses dates to local timezone unless offset is included
  // https://momentjs.com/guides/#/parsing/local-utc-zone/
  const dayPickerProps = {
    selectedDays: value ? moment(value, format).toDate() : undefined,
    modifiers: {
      today: value ? moment(value, format).toDate() : undefined,
      outside: undefined,
    },
    numberOfMonths: 1,
    todayButton: "Today",
    navbarElement: DayPickerNavBar,
    showOutsideDays: true,
    enableOutsideDaysClick: false,
    disabledDays,
    month:
      (value ? moment(value, format).toDate() : defaultMonth) ??
      moment().toDate(),
    captionElement: () => null,
    onDayClick: (day, modifiers, e) => {
      // day is a Date in local timezone
      const clickedDate = moment(day).format("YYYY-MM-DD");
      if (modifiers && !modifiers.disabled) {
        handleDateChange?.(clickedDate);
      }
    },
    onTodayButtonClick: () => {
      handleDateChange?.(moment().format("YYYY-MM-DD"));
    },
  } satisfies DayPickerProps;

  return (
    <div
      data-testid="date-picker-container"
      className={classNames(
        className,
        // todo - migrate this to TW after upgrade (using modifiersClassnames prop)
        "date-selection--container single-date-selection--container"
      )}
      ref={ref}
      {...props}
    >
      <DayPicker {...dayPickerProps} />
    </div>
  );
});
