import classNames from "classnames";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import {
  useEffectOnce,
  useSearchParams,
  useStorage,
} from "./global_functions/hooks";
import { assignAtom } from "./global_functions/jotaiUtils";
import { ClickableDiv } from "@src/straps/utils/ClickableDiv";
import Icon from "@src/straps/base/icons/Icon/Icon";
import { Text } from "@src/straps/base";
import Tooltip from "@src/straps/base/dialogs/Tooltip/Tooltip";

// When adding a new feature flag or variant, type must be defined here

const FeatureFlags = {
  "energy-star-report": ["off", "v1"],
  certifications: ["off", "v1"],
  "compliance-report": ["off", "v1"],
  marketplace: ["off", "on"],
  "demo-features": ["off", "on"],
  "projects-rewards": ["off", "on"],
  "equipment-redesign": ["off", "v1", "v2", "v3"],
  "equipment-pdf": ["off", "on"],
  "utilities-ingestion": ["off", "v1"],
  "decarb-planning": ["off", "v1"],
  "multifamily-tenants-and-spaces": ["off", "demo", "v1"],
  "utilities-arcadia-qa": ["off", "on"],
  "inactive-sites": ["off", "on"],
} as const;

export type FeatureFlagsType = {
  [K in keyof typeof FeatureFlags]: (typeof FeatureFlags)[K][number];
};
// Prefer using this hook over LD's hooks or HOCs
// Root of app must use `withLDProvider` HOC to populate flags
// Supports query param overrides for feature flags in the form ff={key}:{variant}
export function useFeatureFlag<T extends keyof FeatureFlagsType>(
  key: T,
  defaultValue: FeatureFlagsType[T]
): FeatureFlagsType[T] {
  const client = useLDClient();
  const value = client?.variation(key, defaultValue);
  const overrides = useAtomValue(overridesAtom) ?? {};

  if (overrides[key] !== undefined) {
    return overrides[key];
  }

  if (value === undefined) {
    return defaultValue;
  }
  return value as FeatureFlagsType[T];
}

const overridesAtom = atom<{ [key: string]: any } | null>(null);
const assignOverridesAtom = assignAtom(overridesAtom);

// Maintains the specified feature flag params on the client side
export const FeatureFlagOverrideManager = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [savedOverrides, saveOverrides] = useStorage(
    "ff",
    {},
    window.sessionStorage
  );
  const [flagSwitcherStatus, setFlagSwitcherStatus] = useStorage<"on" | "off">(
    "flag-switcher",
    "off",
    window.sessionStorage
  );
  const [overrides, setOverrides] = useAtom(overridesAtom);
  const assignOverrides = useSetAtom(assignOverridesAtom);
  const { getParsedParam, removeSearchParams } = useSearchParams();

  // Load saved flags from session storage
  useEffect(() => {
    if (!overrides) {
      setOverrides(savedOverrides);
    }
    // Only run once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overrides]);

  // Apply overrides from query param and remove them from url
  useEffect(() => {
    const newOverrides = getParsedParam("ff", (ff) =>
      parseFeatureFlagParams(ff)
    );
    if (newOverrides) {
      assignOverrides(newOverrides);
      removeSearchParams("ff");
    }
  }, [getParsedParam, assignOverrides, removeSearchParams]);

  // Apply query param to show container UI, and remove it from url
  useEffect(() => {
    const showFlagParam = getParsedParam("flag-switcher", (status) =>
      status === "on" ? status : "off"
    );
    if (showFlagParam != null) {
      setFlagSwitcherStatus(showFlagParam);
      removeSearchParams("flag-switcher");
    }
  }, [getParsedParam, removeSearchParams, setFlagSwitcherStatus]);

  // Save changes back to session storage
  useEffect(() => {
    if (overrides) {
      saveOverrides(overrides);
    }
  }, [overrides, saveOverrides]);

  // only show UI if flag-switcher query param is set to on, or if there is an existing ff override
  if (
    flagSwitcherStatus !== "on" &&
    Object.keys(overrides ?? []).length === 0
  ) {
    return null;
  }

  return createPortal(
    <div
      className={classNames(
        "fixed z-100 overflow-hidden border border-straps-negative bg-white opacity-50 transition-[bottom,right,opacity] hover:opacity-100 hover:shadow-lg",
        {
          "bottom-4 right-4": isExpanded,
          "bottom-1 right-1 rounded-full": !isExpanded,
        }
      )}
    >
      {isExpanded ? (
        flagSwitcherStatus === "on" ? (
          <FeatureFlagsSwitcher onClose={() => setIsExpanded(false)} />
        ) : (
          <ActiveFlagManager onClose={() => setIsExpanded(false)} />
        )
      ) : (
        <ClickableDiv
          className="flex h-7 w-7 cursor-pointer items-center justify-center text-lg"
          onClick={() => setIsExpanded(true)}
        >
          🏴‍☠️
        </ClickableDiv>
      )}
    </div>,
    document.body
  );
};

function ActiveFlagManager({ onClose }: Readonly<{ onClose: () => void }>) {
  const client = useLDClient();
  const validKeys = new Set(Object.keys(client?.allFlags() ?? {}));
  const [overrides, setOverrides] = useAtom(overridesAtom);
  return (
    <div className="p-2">
      <div className="flex justify-between">
        <Text variant="sa_t-12-700" className="mb-1">
          Active Feature Flag Overrides
        </Text>
        <Icon
          name="close"
          size="small"
          className="cursor-pointer"
          onClick={onClose}
        />
      </div>
      {Object.entries(overrides ?? {}).map(([key, value]) => (
        <div key={key} className="flex items-center gap-1">
          <Icon
            name="remove"
            color="negative"
            size="small"
            className="mb-0.5 cursor-pointer"
            onClick={() => {
              setOverrides(
                Object.fromEntries(
                  Object.entries(overrides ?? {}).filter(([k]) => k !== key)
                )
              );
            }}
          />
          <Text variant="sb_t-12-500">
            {key}: {value}
          </Text>
          {!validKeys.has(key) && !!client && (
            <Tooltip content="Flag not found in LaunchDarkly">
              <Icon
                name="warning"
                size="small"
                color="warning"
                className="mb-0.5"
              />
            </Tooltip>
          )}
        </div>
      ))}
    </div>
  );
}

export function FeatureFlagsSwitcher({
  filter,
  onClose,
}: Readonly<{ onClose?: () => void; filter?: (keyof typeof FeatureFlags)[] }>) {
  const client = useLDClient();
  const [overrides, setOverrides] = useAtom(overridesAtom);
  return (
    <div className="p-2">
      <div className="flex justify-between">
        <Text variant="sa_t-12-700" className="mb-1">
          Feature Flag Overrides
        </Text>
        {onClose && (
          <Icon
            name="close"
            size="small"
            className="cursor-pointer"
            onClick={onClose}
          />
        )}
      </div>
      {Object.entries(FeatureFlags)
        .filter(([flagName]) =>
          filter ? filter.includes(flagName as keyof typeof FeatureFlags) : true
        )
        .map(([flagName, values]) => (
          <div key={flagName} className="flex items-center gap-1">
            <Text variant="sb_t-12-500">{flagName}:</Text>
            {values.map((v) => {
              const isActive = overrides?.[flagName] === v;
              const isDefaultVariant = client?.variation(flagName) === v;

              return (
                <button
                  className={classNames(
                    "m-0.5 flex cursor-pointer items-center justify-center border px-1 transition-colors hover:bg-straps-body",
                    {
                      "border-straps-positive bg-straps-positive-bg": isActive,
                      "border-straps-secondary bg-white":
                        !isActive && !isDefaultVariant,
                      "bg-straps-warning-bg": isDefaultVariant,
                    }
                  )}
                  onClick={() => {
                    setOverrides(
                      isActive
                        ? Object.fromEntries(
                            Object.entries(overrides).filter(
                              ([k]) => k !== flagName
                            )
                          )
                        : { ...overrides, [flagName]: v }
                    );
                  }}
                  key={flagName + v}
                >
                  <Text variant={isActive ? "sa_t-12-700" : "sb_t-12-500"}>
                    {v.toString()}
                  </Text>
                </button>
              );
            })}
          </div>
        ))}
    </div>
  );
}

// Should only be used for testing and stories
export function SetFeatureFlagOverrides({
  overrides,
}: {
  overrides: Partial<FeatureFlagsType>;
}) {
  const setOverrides = useSetAtom(overridesAtom);

  useEffectOnce(() => {
    setOverrides(overrides);
  });

  return null;
}

// utilities
function parseFeatureFlagParams(ff: string) {
  return ff.split(",").reduce((acc, o) => {
    const [k, variant] = o.split(":");
    if (k && variant) {
      if (["true", "false"].includes(variant)) {
        acc[k] = variant === "true";
      } else acc[k] = variant;
    }
    return acc;
  }, {} as { [key: string]: any });
}
