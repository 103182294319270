import React, { ComponentProps, Fragment, ReactNode, useEffect } from "react";

import { startCase } from "lodash";

import Icon, { IconNames } from "@src/straps/base/icons/Icon/Icon";
import { Text } from "@src/straps/base";

import styles from "./Dropdown.module.scss";

import classNames from "classnames";
import useSubscription from "../../../../global_functions/hooks/useSubscription";
import DropdownWrapper from "../../../utils/DropdownWrapper/DropdownWrapper";
import DropdownMultipleSearch from "./variants/DropdownMultipleSearch";
import DropdownSearch from "./variants/DropdownSearch";
import { getObjectEntries, groupBy } from "@src/global_functions/util";
import { DropdownOptionButton } from "./DropdownOptionButton";
import { useControllableState, usePrevious } from "@src/global_functions/hooks";
import DropdownMultiple from "./variants/DropdownMultiple";

export type MultipleOptionDropdownVariant = "multiple" | "multipleSearch";
export type SingleOptionDropdownVariant =
  | "normal"
  | "largeSearch"
  | "normalSearch";
export type DropdownVariant =
  | SingleOptionDropdownVariant
  | MultipleOptionDropdownVariant;

export type DropdownStatus = "default" | "negative" | "positive" | "warning";
export type DropdownTint = "default" | "darker";

export const isMultipleOptionVariant = (
  variant: DropdownVariant
): variant is MultipleOptionDropdownVariant =>
  ["multiple", "multipleSearch"].includes(variant);

const isSingleOptionVariant = (
  variant: DropdownVariant
): variant is SingleOptionDropdownVariant =>
  ["normal", "largeSearch", "normalSearch"].includes(variant);

type ButtonTextSizeOptions = "small" | "base" | "xs";

const buttonTextSizeClasses: Record<
  ButtonTextSizeOptions,
  { tw: string; text: ComponentProps<typeof Text>["variant"] }
> = {
  small: { tw: "text-bs", text: "sb_t-14-500" },
  xs: { tw: "text-xs", text: "sb_t-12-500" },
  base: { tw: "text-bn", text: "sb_t-14-500" },
};

export interface BaseOption<IDType> {
  id: IDType;
  label?: string;
  icon?: IconNames;
  disabled?: boolean;
  nodeLabel?: React.ReactNode;
  supportText?: string;
  new?: boolean;
  backgroundColor?: string;
  imageURL?: string;
}

type OptionWithMetadata<IDType, Metadata> = BaseOption<IDType> & Metadata;

export type Option<
  IDType,
  OptionMetadata extends {} | void = void
> = OptionMetadata extends void
  ? BaseOption<IDType>
  : OptionWithMetadata<IDType, OptionMetadata>;

export type DropdownOptions<
  OptionIDType,
  OptionMetadata extends {} | void = void
> =
  | Option<OptionIDType, OptionMetadata>
  | Array<Option<OptionIDType, OptionMetadata>>
  | null
  | undefined;

export type DropdownProps<OptionIDType, OptionMetadata extends {} | void> = {
  className?: string;
  variant?: DropdownVariant;
  disabled?: boolean;
  placeholder?: string;
  options: Array<Option<OptionIDType, OptionMetadata>>;
  label?: string;
  name?: string;
  onSelect?: (newValue?: DropdownOptions<OptionIDType, OptionMetadata>) => void;
  onCancel?: (prev?: DropdownOptions<OptionIDType, OptionMetadata>) => void;
  dataTestId?: string;
  maxHeight?: number;
  positionOptionsRelative?: boolean;
  selectOnCheck?: boolean;
  onApply?: (options: DropdownOptions<OptionIDType, OptionMetadata>) => void;
  applyText?: string;
  groupBy?: {
    key: keyof Option<OptionIDType, OptionMetadata>;
    labelKey?: keyof Option<OptionIDType, OptionMetadata>;
  };
  status?: DropdownStatus;
  tint?: DropdownTint;
  supportText?: string;
  buttonTextSize?: ButtonTextSizeOptions;
  extendContainerRefs?: React.RefObject<HTMLElement>[];
  extraDropdownRef?: React.RefObject<HTMLDivElement>;
  onFocus?: () => void;
  onBlur?: () => void;
  controlledValue?: DropdownOptions<OptionIDType, OptionMetadata>;
  defaultValue?: DropdownOptions<OptionIDType, OptionMetadata>;
  emptyOptionsPlaceholder?: ReactNode;
  minWidth?: number;
};

const Dropdown = <
  OptionIDType extends string | number = string,
  OptionMetadata extends {} | void = void
>({
  variant = "normal",
  disabled,
  onSelect,
  label,
  name,
  options,
  positionOptionsRelative,
  selectOnCheck,
  applyText,
  placeholder,
  dataTestId,
  maxHeight = 600,
  status = "default",
  tint,
  supportText,
  buttonTextSize = "base",
  extendContainerRefs,
  extraDropdownRef,
  onFocus,
  onBlur,
  onCancel,
  onApply,
  groupBy: groupByProp,
  emptyOptionsPlaceholder,
  minWidth,
  ...props
}: DropdownProps<OptionIDType, OptionMetadata>) => {
  const [dropdownValue, setUncontrolledState] = useControllableState<
    DropdownOptions<OptionIDType, OptionMetadata>
  >(props.controlledValue, props.defaultValue);

  const prevRef = usePrevious(dropdownValue);

  const setDropdownValue = React.useCallback(
    (newValue) => {
      setUncontrolledState(newValue);
      if (typeof newValue === "function") {
        onSelect?.(newValue(prevRef));
      } else {
        onSelect?.(newValue);
      }
    },
    [onSelect, setUncontrolledState, prevRef]
  ) satisfies typeof setUncontrolledState;

  const [closeSubscription, closeEvent] = useSubscription();

  useEffect(() => {
    // close dropdown every time dropdownValue changes for normal dropdown
    if (isSingleOptionVariant(variant)) {
      closeEvent();
    }
  }, [dropdownValue, closeEvent, variant]);

  const dropdownText = React.useMemo(() => {
    if (dropdownValue) {
      return Array.isArray(dropdownValue)
        ? dropdownValue
            .map(
              (option) =>
                option.nodeLabel ||
                option.label ||
                startCase(option.id.toString())
            )
            .join(", ")
        : dropdownValue.nodeLabel ||
            dropdownValue.label ||
            startCase(dropdownValue.id.toString());
    }
    return "";
  }, [dropdownValue]);

  const isChecked = React.useCallback(
    (option: Option<OptionIDType, OptionMetadata>) => {
      if (dropdownValue) {
        if (Array.isArray(dropdownValue)) {
          const selectedIDs = dropdownValue.map((o) => o.id);
          return selectedIDs.includes(option.id);
        } else {
          return dropdownValue.id === option.id && !option.disabled;
        }
      }
      return false;
    },
    [dropdownValue]
  );

  // additional open state tracking for updating styling
  const [menuOpened, setMenuOpened] = React.useState(false);

  const _placeholder =
    placeholder ??
    (["largeSearch", "normalSearch", "multipleSearch"].includes(variant)
      ? "Search..."
      : "Select...");

  return (
    <div className="w-full">
      {label && (
        <Text
          variant="sb_t-14-500"
          color="secondary"
          className="pb-1"
          data-testid="dropdown-label"
        >
          <label htmlFor={name}>{label}</label>
        </Text>
      )}
      <DropdownWrapper
        maxHeight={variant !== "multipleSearch" ? maxHeight : undefined}
        disabled={disabled}
        fullWidth
        minWidth={minWidth}
        allowRenderAbove={
          !["largeSearch", "normalSearch", "multipleSearch"].includes(variant)
        }
        positionRelative={positionOptionsRelative}
        closeSubscription={closeSubscription}
        open={menuOpened}
        setOpen={setMenuOpened}
        extraRefs={extendContainerRefs}
        extraDropdownRef={extraDropdownRef}
        innerClassName={classNames({
          "my-0.5": variant !== "largeSearch",
        })}
        as={
          <button
            data-testid={dataTestId ?? "dropdown-container"}
            className={classNames(
              "flex w-full items-center justify-between px-[18px] py-2 outline-none transition-all focus:outline-none",
              "group text-straps-primary placeholder:text-straps-tertiary hover:shadow-lg",
              {
                "pointer-events-none cursor-not-allowed opacity-25": disabled,
                "h-[70px]": variant === "largeSearch",
                "h-[34px] rounded-full ": variant !== "largeSearch",
                "bg-pure-white": status === "default",
                "bg-straps-positive-bg": status === "positive",
                "bg-straps-negative-bg": status === "negative",
                "bg-straps-warning-bg": status === "warning",
                "bg-straps-body": tint === "darker",
              }
            )}
          >
            <div
              className={classNames(
                "flex w-full max-w-[90%] flex-row items-center gap-x-3",
                styles.dropdownTextContainer
              )}
            >
              {dropdownValue && dropdownText ? (
                <>
                  {!Array.isArray(dropdownValue) && dropdownValue.icon && (
                    <div className="flex w-4 min-w-[16px] flex-row items-center">
                      <Icon name={dropdownValue.icon as IconNames} />
                    </div>
                  )}
                  <Text
                    as="span"
                    variant={
                      variant === "largeSearch"
                        ? "h5_t-18-700"
                        : buttonTextSizeClasses[buttonTextSize].text
                    }
                    className="pointer-events-none block w-full truncate text-left text-straps-primary"
                  >
                    {dropdownText}
                  </Text>
                </>
              ) : (
                <span
                  className={classNames(
                    "whitespace-nowrap text-straps-tertiary",
                    buttonTextSizeClasses[buttonTextSize].tw
                  )}
                >
                  {_placeholder}
                </span>
              )}
            </div>
            <div className="flex w-3 min-w-[12px] flex-row items-center">
              <Icon
                name="caret-down"
                size="small"
                className={classNames(
                  "transition-all hover:text-straps-primary group-hover:text-straps-primary group-focus:text-straps-primary",
                  {
                    "-rotate-180 text-straps-primary": menuOpened,
                    "text-straps-secondary": !menuOpened,
                  }
                )}
              />
            </div>
          </button>
        }
        onFocus={onFocus}
        onBlur={onBlur}
      >
        <div
          className={classNames({
            "py-2": variant !== "multipleSearch" && variant !== "largeSearch",
            "flex min-h-0 flex-col": variant === "multipleSearch",
          })}
        >
          {emptyOptionsPlaceholder &&
            options.length === 0 &&
            emptyOptionsPlaceholder}
          {variant === "multipleSearch" && (
            <DropdownMultipleSearch
              name={name}
              options={options}
              selectOnCheck={selectOnCheck}
              applyText={applyText}
              closeEvent={closeEvent}
              value={Array.isArray(dropdownValue) ? dropdownValue : []}
              setValue={setDropdownValue}
              placeholder={_placeholder}
              onApply={onApply}
              onCancel={onCancel}
            />
          )}
          {["normalSearch", "largeSearch"].includes(variant) && (
            <DropdownSearch
              name={name}
              placeholder={_placeholder}
              options={options}
              setValue={setDropdownValue}
              value={Array.isArray(dropdownValue) ? undefined : dropdownValue}
              variant={variant}
            />
          )}
          {variant === "multiple" && (
            <DropdownMultiple
              options={options}
              value={Array.isArray(dropdownValue) ? dropdownValue : []}
              setValue={setDropdownValue}
            />
          )}
          {variant === "normal" &&
            (groupByProp
              ? getObjectEntries(groupBy(options, groupByProp.key)).map(
                  ([group, groupOptions]) => (
                    <Fragment key={`${group}`}>
                      <div className="ml-4 mt-4 w-max border-b border-straps-secondary text-xs text-straps-secondary">
                        {group}
                      </div>
                      {groupOptions.map((option) => (
                        <DropdownOptionButton
                          key={`${option.id}-${group}`}
                          option={option}
                          variant={variant}
                          handleSelect={setDropdownValue}
                          isChecked={isChecked}
                        />
                      ))}
                    </Fragment>
                  )
                )
              : options.map((option) => (
                  <DropdownOptionButton
                    key={`${option.id}`}
                    option={option}
                    variant={variant}
                    handleSelect={setDropdownValue}
                    isChecked={isChecked}
                  />
                )))}
        </div>
      </DropdownWrapper>

      {supportText && (
        <Text
          variant="sb_t-14-500"
          className="pt-2"
          color={
            disabled
              ? "tertiary"
              : status === "negative"
              ? "negative"
              : "secondary"
          }
        >
          {supportText}
        </Text>
      )}
    </div>
  );
};

export default Dropdown;
