import { useUtilitiesArcadiaQAHeader } from "@src/utilitiesArcadiaQA";
import { useQuery, useQueryClient } from "react-query";
import FetchClients, { ClientOutput } from "../client";

export type ManualBill = ClientOutput["utilities"]["ManualBill"];

const manualBill = {
  useQueryAllRecentlyUploaded,
  useQueryAllSuccessful,
  useInvalidateManualBillQueries,
} as const;

export default manualBill;

const MANUAL_BILL_KEY = "utilities/manual-bills";
function useQueryAll(site_id: number, statuses: ManualBill["status"][]) {
  const routeToHeader = useUtilitiesArcadiaQAHeader();

  return useQuery([MANUAL_BILL_KEY, site_id, statuses], async () => {
    const { data, error } = await FetchClients.utilities.GET(
      "/{site_id}/manual_bills",
      {
        params: {
          path: {
            site_id,
          },
          query: {
            statuses,
          },
        },
        headers: {
          "X-Route-To": routeToHeader,
        },
      }
    );
    if (data) return data;
    throw new Error(error);
  });
}

// We show these on the utilities account management page
const RECENTLY_UPDATED_STATUSES: ManualBill["status"][] = [
  "failure",
  "in_progress",
  "incomplete",
];
function useQueryAllRecentlyUploaded(site_id: number) {
  return useQueryAll(site_id, RECENTLY_UPDATED_STATUSES);
}

// We show these on the documents page
const SUCCESS_STATUSES: ManualBill["status"][] = ["success"];
function useQueryAllSuccessful(site_id: number) {
  return useQueryAll(site_id, SUCCESS_STATUSES);
}

function useInvalidateManualBillQueries(siteId: number) {
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries([MANUAL_BILL_KEY, siteId]);
}
