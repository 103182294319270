import FetchClients, { ClientOutput } from "../client";
import { useQuery as useReactQuery } from "react-query";
import { CamelCasedPropertiesDeep } from "type-fest";
import { recursiveCamelCaseCipher } from "@src/global_functions/postgrestApi";
import { FloorWithSpaces } from "./util";
import * as R from "ramda";

type SnakeCaseFloorsResponse =
  ClientOutput["tenantsAndSpaces"]["FloorsHierarchy"];
type FloorsResponse = CamelCasedPropertiesDeep<
  Omit<SnakeCaseFloorsResponse, "floors"> & {
    floors: FloorWithSpaces[];
  }
>;

const floors = { useQuery, useQueryAll } as const;

export default floors;

export const FLOORS_QUERY_KEY = "/floors";

const getBuildingFloors = async (
  site_id: number,
  building_id?: number | null
) => {
  if (typeof building_id !== "number") throw new Error("Invalid building id");
  const { data, error } = await FetchClients.tenantsAndSpaces.GET(
    "/{site_id}/buildings/{building_id}/floors",
    {
      params: { path: { site_id, building_id } },
    }
  );
  if (data) return recursiveCamelCaseCipher(data) as FloorsResponse;
  throw new Error(error);
};

function useQuery(site_id: number, building_id?: number | null) {
  return useReactQuery(
    [FLOORS_QUERY_KEY, site_id, building_id],
    () => getBuildingFloors(site_id, building_id),
    {
      refetchOnWindowFocus: false,
    }
  );
}

function useQueryAll(site_id: number, building_ids: number[]) {
  return useReactQuery(
    [FLOORS_QUERY_KEY, site_id, R.sort((a, b) => a - b, building_ids).join()],
    () => Promise.all(building_ids.map((id) => getBuildingFloors(site_id, id))),
    {
      refetchOnWindowFocus: false,
      enabled: !!building_ids.length,
    }
  );
}
