import SitesContext, {
  currentSiteIdAtom,
  useSite,
} from "@src/context/SitesContext";
import { useSearchParamAtom, useStorage } from "@src/global_functions/hooks";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { matchPath, useHistory, useLocation } from "react-router-dom";

const useAccounts = () => {
  const { allSites, loading } = useContext(SitesContext);

  const accounts: Array<{ id: number; label: string }> = useMemo(() => {
    const accountMap = new Map(
      allSites.map(({ accountId, accountName }) => [accountId, accountName])
    );
    return Array.from(accountMap.entries())
      .map(([id, label]) => ({ id, label }))
      .sort(({ label: a }, { label: b }) => a.localeCompare(b));
  }, [allSites]);

  return {
    accounts,
    isLoading: loading,
  };
};

interface Account {
  id: number;
  label: string;
}
interface CurrentAccountState {
  isLoading: boolean;
  account?: Account;
  accountId?: number;
  accounts?: Account[];
  setAccountId?: (accountId?: number) => void;
}
const currentAccountStateAtom = atom<CurrentAccountState>({
  isLoading: true,
});
export function useCurrentAccount() {
  return useAtomValue(currentAccountStateAtom);
}
export function useCurrentAccountSites() {
  const { allSites, loading } = useContext(SitesContext);
  const { accountId } = useCurrentAccount();
  return {
    allSites: useMemo(
      () => allSites.filter((site) => site.accountId === accountId),
      [allSites, accountId]
    ),
    isLoading: loading,
  };
}

export const CURRENT_ACCOUNT_STORAGE_KEY = "currentAccountId";

/*
 * Manages the current account shown in the top nav and used on most pages
 * Acts as a provider for useCurrentAccount hook
 */
export function CurrentAccountManager() {
  /*
   * Current Account can come from 4 sources, ordered by precedence:
   *
   * 1. Current site's account (/site/:siteId/) OR (/equipment/:siteId/) OR (/certifications/:siteId/)
   * 2. Account ID search param (?accountId=123)
   * 3. Local storage (most recent account ID)
   * 4. First account in list of accounts that the current user has access to
   */
  const setCurrentAccountState = useSetAtom(currentAccountStateAtom);
  const history = useHistory();
  const location = useLocation();
  const currentSiteId = useAtomValue(currentSiteIdAtom);
  const currentSite = useSite(currentSiteId);
  const [searchParamAccountId, setSearchParamAccountId] = useSearchParamAtom(
    "accountId",
    Number
  );
  const { accounts, isLoading } = useAccounts();

  const accountFromCurrentSite = useMemo(() => {
    if (!currentSite?.accountId) {
      return null;
    }
    return {
      id: currentSite.accountId,
      label: currentSite.accountName,
    };
  }, [currentSite]);

  const accountFromSearchParam = useMemo(
    () =>
      searchParamAccountId
        ? accounts.find((e) => e.id === searchParamAccountId)
        : undefined,
    [accounts, searchParamAccountId]
  );

  const [storedAccountId, setStoredAccountId] = useStorage<number | undefined>(
    CURRENT_ACCOUNT_STORAGE_KEY,
    undefined
  );
  const accountFromLocalStorage = useMemo(
    () =>
      storedAccountId
        ? accounts.find((e) => e.id === storedAccountId)
        : undefined,
    [accounts, storedAccountId]
  );

  const accountFromFirstInList = accounts[0];

  const account =
    accountFromCurrentSite ??
    accountFromSearchParam ??
    accountFromLocalStorage ??
    accountFromFirstInList;
  const accountId = account?.id;

  useEffect(() => {
    if (accountId !== undefined && accountId !== storedAccountId) {
      setStoredAccountId(accountId);
    }
  }, [accountId, setStoredAccountId, storedAccountId]);

  const setAccountId = useCallback(
    (nextAccountId) => {
      if (nextAccountId === accountId) {
        return;
      }
      if (accountFromCurrentSite) {
        const equipmentPageMatch = matchPath(location.pathname, {
          path: "/equipment",
        });
        const certificationsPageMatch = matchPath(location.pathname, {
          path: "/certifications",
        });

        // Current route determines the account, so we must redirect to homepage if account changes
        // conditionally direct to portfolio equipment/certifications page if we are in the equipment/certifications routers respectively
        if (equipmentPageMatch) {
          history.push("/equipment");
        } else if (certificationsPageMatch) {
          history.push("/certifications");
        } else {
          history.push("/");
        }
      }
      setSearchParamAccountId(nextAccountId);
    },
    [
      accountId,
      setSearchParamAccountId,
      accountFromCurrentSite,
      history,
      location.pathname,
    ]
  );

  useEffect(() => {
    setCurrentAccountState({
      isLoading,
      account,
      accountId,
      accounts,
      setAccountId,
    });
  }, [
    setCurrentAccountState,
    isLoading,
    account,
    accountId,
    accounts,
    setAccountId,
  ]);

  return null;
}
