import Fuse from "fuse.js";
import Icon from "@src/straps/base/icons/Icon/Icon";
import { useEffect, useMemo, useRef, useState } from "react";
import { DropdownProps, Option } from "../Dropdown";
import { DropdownOptionButton } from "../DropdownOptionButton";
import classNames from "classnames";

export default function DropdownSearch<
  OptionIDType extends string | number = string,
  OptionMetadata extends {} | void = void
>({
  name,
  placeholder,
  options,
  setValue,
  value,
  variant = "largeSearch",
}: Pick<
  DropdownProps<OptionIDType, OptionMetadata>,
  "name" | "placeholder" | "options" | "variant"
> & {
  value: Option<OptionIDType, OptionMetadata> | undefined | null;
  setValue: (option: Option<OptionIDType, OptionMetadata>) => void;
}) {
  const { search, setSearch, filteredOptions } = useDropdownSearch(options);
  const inputRef = useRef<HTMLInputElement>(null);
  const isLargeVariant = variant === "largeSearch";
  const isNormalVariant = variant === "normalSearch";

  useEffect(() => {
    inputRef.current?.focus();
  }, [inputRef]);
  return (
    <>
      {isLargeVariant && <div className="h-px w-full bg-straps-accent-3" />}
      <div
        data-testid={`dropdown-${
          isLargeVariant ? "large" : "normal"
        }-container`}
        className="flex w-full flex-row"
      >
        <div
          className={classNames("flex w-full items-center gap-2 px-[18px]", {
            "h-15": isLargeVariant,
            "h-9": isNormalVariant,
          })}
        >
          <Icon
            name="search"
            color="secondary"
            className="shrink-0"
            size={isLargeVariant ? "medium" : "small"}
          />
          <input
            data-testid={`dropdown-${
              isLargeVariant ? "large" : "normal"
            }-search-input`}
            name={name}
            ref={inputRef}
            className="flex-1 border-0 bg-transparent text-bs font-medium outline-none placeholder:text-straps-tertiary"
            placeholder={placeholder}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>
      {isLargeVariant && <div className="h-px w-full bg-straps-accent-3" />}
      {filteredOptions.map((option) => (
        <DropdownOptionButton
          key={option.id}
          option={option}
          variant={variant}
          handleSelect={setValue}
          isChecked={() => option.id === value?.id}
        />
      ))}
    </>
  );
}

export const useDropdownSearch = <
  OptionIDType extends string | number = string,
  OptionMetadata extends {} | void = void
>(
  options: Array<Option<OptionIDType, OptionMetadata>>
) => {
  const [search, setSearch] = useState("");
  useEffect(() => {
    return () => {
      setSearch("");
    };
  }, []);
  const fuse = useMemo(
    () =>
      new Fuse(options, {
        shouldSort: true,
        threshold: 0.4,
        keys: ["label"],
      }),
    [options]
  );
  const filteredOptions = useMemo(() => {
    if (!search) return options;
    return fuse.search(search).map(({ item }) => item);
  }, [search, fuse, options]);

  return {
    search,
    setSearch,
    filteredOptions,
  } as const;
};
