import FetchClients, { ClientOutput } from "../client";
import {
  useMutation,
  useQuery as useReactQuery,
  useQueryClient,
} from "react-query";
import { CamelCasedProperties } from "type-fest";
import {
  recursiveCamelCaseCipher,
  recursiveSnakeCaseCipher,
} from "@src/global_functions/postgrestApi";

const unitTypes = { useQuery, mutations: { useDelete, useUpsert } } as const;
export default unitTypes;

export type UnitType = CamelCasedProperties<
  ClientOutput["tenantsAndSpaces"]["UnitType"]
>;

const UNIT_TYPES_QUERY_KEY = "unit_types";

const getUnitTypes = async (site_id: number) => {
  const { response, error, data } = await FetchClients.tenantsAndSpaces.GET(
    "/{site_id}/unit_types",
    {
      params: {
        path: {
          site_id,
        },
      },
    }
  );
  if (response.ok) return recursiveCamelCaseCipher(data ?? []) as UnitType[];
  throw new Error(error);
};

function useQuery(siteId: number) {
  return useReactQuery({
    queryFn: () => getUnitTypes(siteId),
    queryKey: [UNIT_TYPES_QUERY_KEY, siteId],
  });
}

const upsertUnitType = async (
  site_id: number,
  { siteId: _, ...unitType }: UnitType
) => {
  const { response, error } = await FetchClients.tenantsAndSpaces.PUT(
    "/{site_id}/unit_types",
    {
      params: {
        path: {
          site_id,
        },
      },
      body: {
        site_id,
        ...recursiveSnakeCaseCipher(unitType),
      },
    }
  );
  if (response.ok) return;
  throw new Error(error);
};

function useUpsert(siteId: number) {
  const queryClient = useQueryClient();
  return useMutation((unitType: UnitType) => upsertUnitType(siteId, unitType), {
    onSuccess: () =>
      queryClient.invalidateQueries([UNIT_TYPES_QUERY_KEY, siteId]),
  });
}

const deleteUnitType = async (site_id: number, unit_type_id: number) => {
  const { response, error } = await FetchClients.tenantsAndSpaces.DELETE(
    "/{site_id}/unit_types/{unit_type_id}",
    {
      params: {
        path: {
          site_id,
          unit_type_id,
        },
      },
    }
  );
  if (response.ok) return;
  throw new Error(error);
};
function useDelete(siteId: number) {
  const queryClient = useQueryClient();
  return useMutation(
    (unitTypeId: number) => deleteUnitType(siteId, unitTypeId),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([UNIT_TYPES_QUERY_KEY, siteId]),
    }
  );
}
