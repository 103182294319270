import Cookies from "js-cookie";
import { useQuery } from "react-query";
import { VITE_TENANTS_AND_SPACES_BASE_URL } from "../../../../env";
import { recursiveCamelCaseCipher } from "../../../postgrestApi";
import request from "../../../request";
import { SpaceWithEnergyStarRequirements } from "../types";
import { tenantsAndSpacesKeys } from "../queryKeyFactory";
import backpackSdk from "@src/global_functions/backpackSdk/sdk";

const getSpacesWithEnergyStarRequirements = async (
  siteId: number,
  buildingId?: number | null
): Promise<SpaceWithEnergyStarRequirements[]> => {
  if (typeof buildingId !== "number") {
    return Promise.reject(new Error("Invalid Building ID"));
  }

  return request
    .get(
      `${VITE_TENANTS_AND_SPACES_BASE_URL}/sites/${siteId}/buildings/${buildingId}/spaces`
    )
    .query({ include: "energy_star" })
    .set("Authorization", `Bearer ${Cookies.get("jwt") ?? ""}`)
    .then(({ body }) => recursiveCamelCaseCipher(body));
};

export const SPACES_WITH_ESTAR_REQUIREMENT_KEY =
  "getSpacesWithEnergyStarRequirements";
export const useGetSpacesWithEnergyStarRequirements = (siteId: number) => {
  const { data: building } =
    backpackSdk.tenantsAndSpaces.building.useQuery(siteId);
  return useQuery(
    tenantsAndSpacesKeys.query(
      siteId,
      "space",
      SPACES_WITH_ESTAR_REQUIREMENT_KEY
    ),
    () => getSpacesWithEnergyStarRequirements(siteId, building?.id),
    { enabled: !!building?.id }
  );
};
